<template>
  <div class="layout-error">
    <div class="layout-error__title">존재하지 않는 페이지 입니다.</div>
    <p class="layout-error__text">
      주소를 잘못 입력했거나 페이지가 이동했을 수 있습니다. <br />
    </p>
    <nuxt-link class="btn btn--primary" to="/">홈으로 돌아가기</nuxt-link>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-error {
  max-width: 610px;
  margin: 0 auto;
  padding: 226px 1rem 150px;
  text-align: center;
  line-height: 1.48;
  word-break: keep-all;

  &__title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 2rem;
  }
}
</style>
