import { mapState } from 'vuex'
import uuid from 'uuid'
import axios from 'axios'

export default {
  data() {
    return {
      processing: false,
      uuid: uuid.v1(),
      LEAD_API_URL: {
        development: 'http://localhost:8000/api/lead/',
        staging: 'https://sf-api.stag.heumtax.com/api/lead/',
        production: 'https://sf-api.heumtax.com/api/lead/'
      }
    }
  },
  computed: {
    ...mapState(['utmParams'])
  },
  methods: {
    requestLeadApi(method, endpoint, data) {
      const timestamp = new Date().getTime()
      const url = `${
        this.LEAD_API_URL[
          location.hostname.split('.').includes('netlify')
            ? 'staging'
            : process.env.NODE_ENV
        ]
      }${endpoint}?timestamp=${timestamp}`

      this.$sentry.setTags({
        ...data
      })

      return axios({ method, url, data, timeout: 5000 })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          this.$sentry.captureException(
            `혜움 랜딩페이지 pipedrive 업데이트 시 에러 발생`,
            {
              extra: {
                error: err
              }
            }
          )
        })
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return

        if (this.processing === true) {
          return
        }

        this.processing = true
        this.consultationRequest().then(() => {
          this.processing = false
        })
      })
    }
  }
}
