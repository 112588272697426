import jsCookie from 'js-cookie'

export default function(context) {
  const { store, query } = context
  const utmList = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'phone',
    'ouid'
  ]

  const availableUtmCode = {}

  utmList.forEach((utmCode) => {
    const utmValue = query[utmCode] || jsCookie.get(utmCode)
    if (utmValue) {
      localStorage.setItem(utmCode, utmValue)
      availableUtmCode[utmCode] = utmValue
      return
    }

    if (localStorage.getItem(utmCode)) {
      availableUtmCode[utmCode] = localStorage.getItem(utmCode)
    }
  })

  store.commit('SET_UTM_PARAMS', availableUtmCode)
}
