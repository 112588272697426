<template>
  <header
    :class="{
      'header--has-stickyBanner': stickyBanner,
      'header--invert': scrolled,
      'header--menu-opend': menuOpened
    }"
    class="header"
  >
    <div class="header__container">
      <div class="header__logo">
        <heum-logo :invert="scrolled || invertFont" />
      </div>

      <div class="header__navigation">
        <navigation @toggleMenu="toggleMenu" :invert="scrolled || invertFont" />
      </div>

      <div class="header__links">
        <header-links :invert="scrolled || invertFont" />
      </div>

      <div @click="toggleMenu" class="header__hamberger-button">
        <hamberger-button
          :invert="scrolled || invertFont"
          :menuOpened="menuOpened"
        />
      </div>

      <div @click="toggleMenu" class="header__dimmed"></div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import HeumLogo from '~/components/common/heum-logo.vue'
import Navigation from '~/components/common/navigation.vue'
import HeaderLinks from '~/components/common/header-links.vue'
import HambergerButton from '~/components/common/hamberger-button.vue'

export default {
  components: {
    HeumLogo,
    Navigation,
    HeaderLinks,
    HambergerButton
  },
  props: {
    invertFont: {
      type: Boolean,
      required: true,
      default: false
    },
    stickyBanner: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menuOpened: false
  }),
  computed: {
    ...mapState(['scrollPositionY']),
    scrolled() {
      return this.scrollPositionY.top >= (this.stickyBanner ? 76 : 1)
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    }
  }
}
</script>

<style lang="scss">
.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 76px;
  z-index: 20;
  transition: background-color 0.2s;

  &--has-stickyBanner {
    top: 76px;
  }

  &--invert {
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  }

  &--menu-opend {
    @include av-mq(portable) {
      .header__navigation {
        transform: translateX(-100%);
      }

      .header__dimmed {
        display: block;
        right: 0;
        opacity: 0.7;
      }
    }
  }

  &__container {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__logo {
    display: inline-block;
    padding: 22px;
  }

  &__links {
    position: absolute;
    top: 0;
    right: 76px;
    z-index: 3;

    @include av-mq(desk) {
      right: 0;
      z-index: 4;
    }
  }

  &__hamberger-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 76px;
    z-index: 4;

    @include av-mq(desk) {
      display: none;
    }
  }

  &__navigation {
    position: fixed;
    top: 0;
    left: 100%;
    width: 280px;
    height: 100%;
    transform: translateX(0);
    background-color: #fff;
    z-index: 4;
    transition: transform 0.3s;

    @include av-mq(desk) {
      position: absolute;
      left: 76px;
      width: calc(100% - 76px);
      padding: 0 0 0 24px;
      background-color: initial;
    }
  }

  &__dimmed {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #212529;
    transition: opacity 0.3s;
  }
}
</style>
