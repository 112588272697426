import { createClient } from '~/plugins/contentful'
const contentfulClient = createClient()

export const state = () => ({
  partnerContent: {},
  utmParams: {},
  scrollPositionY: {
    top: 0,
    bottom: 0
  }
})

export const getters = {
  getPartnerContent(state) {
    const result = {}

    for (const [key, value] of Object.entries(state.partnerContent)) {
      if (value.sys && value.sys.type === 'Asset') {
        result[key] = value.fields.file.url
      } else {
        result[key] = value
      }
    }

    return result
  }
}

export const mutations = {
  SET_PARTNER_CONTENT(state, content) {
    state.partnerContent = content
  },
  SET_UTM_PARAMS(state, utmParams) {
    state.utmParams = utmParams
  },
  SET_SCROLL_POSITION_Y(state, position) {
    state.scrollPositionY = {
      top: position.top,
      bottom: position.bottom
    }
  }
}

export const actions = {
  GET_PARTNER_CONTENT(context, partnerName) {
    contentfulClient
      .getEntries({
        'fields.name': partnerName,
        content_type: 'partner'
      })
      .then((res) => {
        context.commit('SET_PARTNER_CONTENT', res.items[0].fields)
      })
  }
}
