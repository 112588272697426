import { mapState } from 'vuex'
import Header from '~/components/common/header.vue'
import Footer from '~/components/common/footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapState(['utmParams'])
  },
  methods: {
    handleScroll() {
      this.$store.commit('SET_SCROLL_POSITION_Y', {
        top: window.pageYOffset,
        bottom: window.pageYOffset + window.innerHeight
      })
    }
  },
  mounted() {
    this.$sentry.setTags({
      ...this.utmParams
    })

    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
