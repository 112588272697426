<template>
  <div class="wrapper">
    <Header :invert-font="true" />
    <nuxt />
    <Footer />
  </div>
</template>

<script>
import Layout from '~/layouts/mixins/layout.js'
export default {
  mixins: [Layout]
}
</script>
