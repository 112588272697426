import Vue from 'vue'

export default function({ store }) {
  Vue.mixin({
    methods: {
      displayPartnerContent(defaultValue, contentKey) {
        return store.getters.getPartnerContent[contentKey]
          ? store.getters.getPartnerContent[contentKey]
          : defaultValue
      }
    }
  })
}
