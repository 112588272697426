<template>
  <button
    :class="{ 'is-active': menuOpened, 'is-invert': invert }"
    class="hamburger hamburger--3dx"
    type="button"
    aria-label="Menu"
    aria-controls="navigation"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    menuOpened: {
      type: Boolean,
      required: true,
      default: false
    },
    invert: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style lang="scss">
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 28px !default;
$hamburger-padding-y: 24px !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $black !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (3dx) !default;

// Base Hamburger (We need this)
// ==================================================
.hamburger {
  padding: 29px $hamburger-padding-x 28px;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: $hamburger-hover-opacity;
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }

  &.is-invert {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

// Hamburger types
// ==================================================
@if index($hamburger-types, 3dx) {
  /*
     * 3DX
     */
  .hamburger--3dx {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
          background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent !important;
        transform: rotateY(180deg);

        &::before {
          transform: translate3d(
              0,
              $hamburger-layer-height + $hamburger-layer-spacing,
              0
            )
            rotate(45deg);
        }

        &::after {
          transform: translate3d(
              0,
              ($hamburger-layer-height + $hamburger-layer-spacing) * -1,
              0
            )
            rotate(-45deg);
        }
      }
    }
  }
}
</style>
