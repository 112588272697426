<template>
  <div
    :class="{ 'header-links--invert': invert }"
    v-show="isLoaded"
    class="header-links"
  >
    <template
      v-if="$route.name !== 'consulting' && $route.name !== 'consulting-detail'"
    >
      <a
        href="https://report.heumtax.com/"
        target="_blank"
        class="header-links__item"
        >로그인</a
      >
      <btn-consultation
        custom-class="header-links__item header-links__item--consulting"
      />
    </template>
  </div>
</template>

<script>
import BtnConsultation from '@/components/common/btn-consultation.vue'

export default {
  components: { BtnConsultation },
  props: {
    invert: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    isLoaded: false
  }),
  mounted() {
    setTimeout(() => {
      this.isLoaded = true
    }, 0)
  }
}
</script>

<style lang="scss">
.header-links {
  line-height: 76px;

  &__item {
    display: inline-block;
    margin: 0 10px 0 0;
    font-size: 14px;
    letter-spacing: -0.71px;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;

    @include av-mq(desk) {
      margin: 0 22px 0 0;
      font-size: 16px;
    }

    &:last-of-type {
      @include av-mq(portable) {
        margin-right: 0;
      }
    }

    &--hide {
      @include av-mq(portable) {
        display: none;
      }
    }

    &--consulting {
      background-color: #1f75e8;
      padding: 8px 0.75rem 8px;
      line-height: 1.48;
      border-radius: 5px;
      color: #fff !important;
      font-weight: bold;

      &.exp-button-color-1 {
        background: #ffdf00;
        border-color: #ffdf00;
        color: #1b1b1b !important;
      }

      @include av-mq(desk) {
        padding: 10px 1rem;
      }
    }
  }

  &--invert {
    .header-links__item {
      color: initial;
    }
  }
}
</style>
