<template>
  <div class="wrapper">
    <div v-if="enableStickyBanner" class="sticky-area">
      <march-fastfive-event />
    </div>
    <Header :invert-font="true" :sticky-banner="enableStickyBanner" />
    <nuxt />
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Layout from '~/layouts/mixins/layout.js'
import MarchFastfiveEvent from '~/components/sticky-banner/march-fastfive-event.vue'

export default {
  components: {
    MarchFastfiveEvent
  },
  mixins: [Layout],
  computed: {
    ...mapGetters({
      partnerContent: 'getPartnerContent'
    }),
    enableStickyBanner() {
      return !this.partnerContent.name
    }
  }
}
</script>
<style lang="scss">
.sticky-area {
  height: 76px;
}
</style>
