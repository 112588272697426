<template>
  <nuxt-link :class="customClass" :to="toRoute">
    {{ linkText }}
  </nuxt-link>
</template>

<script>
import consulting from '@/components/mixins/consulting'

export default {
  name: 'BtnConsultation',
  mixins: [consulting],
  props: {
    customClass: {
      type: String,
      default: ''
    },
    toRoute: {
      type: String,
      default: '/consulting'
    },
    linkText: {
      type: String,
      default: '상담 신청하기'
    }
  }
}
</script>
